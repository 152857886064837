@import './fonts.css';

/* label {
  font-family: THSarabunPSK, Roboto;
} */

/* body {
  font-size: 16px;
  font-family: THSarabunPSK, sans-serif;
} */

body {
  font-family: 'Kanit';
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); */
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 85, 85);
}

.partition {
  box-sizing:border-box;
}

article{ 
  min-height: 100vh; 
  margin: 0; 

  display: grid;
  grid-template-rows: auto 1fr auto;
}

header{ 
  min-height:50px;
}

footer{ 
  min-height:50px; 
}

.wrapper {
  height: 100vh;
  display: flex;

  flex-direction: column;
}

main {
  flex: 1;
}

.ant-table-cell:has(.colorful-col.orange) {
  background-color: #fff0b3 !important;
 
}

.ant-table-cell:has(.colorful-col.green) {
  background-color: #ccffcc !important;

}

/* ----------------------------- Header */

/* .menuBar {
  overflow: auto;
  box-shadow: 0 0 20px #949494;
  background-color: white;
}

.logo {
  width: 50;
  float: left;
}

.menuCon {
  width: calc(100% - 30px);
  float: left;
  height: 48px;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon {
  float: right;
}

.barsMenu {
  float: right;
  height: 48px;
  display: none;
  background: none;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
} */

.otpContainer {
  margin: 5% auto;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.3rem;
  font-size: 24px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(131, 131, 131, 0.3);
}

.otpInput:focus {
  border: 2px solid rgba(131, 131, 131, 0.3);
  box-shadow: 0 0 3px #1670BE;
  outline-offset: 0px;
  outline: none;
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 375px) {
  .otpInput {
    width: 1.5rem !important;
    height: 1.5rem;
    font-size: 1rem;
    padding: 8px;
  }
}

.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}
